import React, { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import {Container} from '../components/Container/Container'
// import ContentPresenter from '../components/ContentPresenter/ContentPresenter'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'
import { TEXT_PAGES_QUERY } from '../apollo/query/all_text_page'
import client from "../apollo/apollo-client";
import parse from 'html-react-parser'
import Page404 from "../components/Page404";
import { IMG_URL } from '../apollo/client-url'
import * as Sentry from '@sentry/nextjs'

export async function getServerSideProps (context) {
  try {
    const slug = context.params.TextPage
    
    const { data, error } = await client.query({
      query: TEXT_PAGES_QUERY,
      variables: {
        where: {
          currentUrl: slug
        },
        slug: {
          slug: slug
        }
      },
      fetchPolicy: 'no-cache'
    })
    
    const pageInfo = data.allTextPage?.[0] || null
    const redirectInfo = data?.allPageRedirects?.[0] || null
    const redirectCurrentUrl = redirectInfo?.currentUrl
    const hasRedirect = redirectInfo ? redirectCurrentUrl === slug : false

    return { props: {
      pageInfo,
      allTextPages: data.allTextPages,
      allBlogs: data.allBlogs,
      error: error || '',
      redirectTo: hasRedirect ? redirectInfo?.redirectUrl : null
    }}
  } catch (error) {
    Sentry.captureException(error)
    return { props: { allTextPages: [], allBlogs: [], pageInfo: null, error: '', redirectTo: null  }}
  }
}

function TextPage (props) {
  const router = useRouter()
  const asPath = router?.asPath
  
  const { pageInfo, redirectTo, allTextPages, allBlogs } = props
  
  useEffect(() => {
    if (asPath === '/customer-reviews') {
      router.push('https://au.trustpilot.com/review/yesloyal.com')
    }

    if (redirectTo) {
      router?.push(redirectTo)
    }
    
  }, [asPath])

  if (redirectTo || asPath === '/customer-reviews' || asPath?.includes('[')) {
    return null
  }
  
  if (!pageInfo) {
    return (
      <Page404 pages={[]} />
    )
  }
  
  const pageContent = pageInfo?.content
  
  return !redirectTo ? (
    <div className="static">
      <Head>
        <title>{pageInfo?.title}</title>
        {pageInfo?.metaTitle && <meta name="title" content={pageInfo?.metaTitle} />}
        {pageInfo?.metaTitle &&  <meta property="og:title" content={pageInfo?.metaTitle} />}
        {pageInfo?.metaTitle && <meta property="twitter:title" content={pageInfo?.metaTitle} />}
        
        {pageInfo?.description && <meta name="description" content={pageInfo?.description} />}
        {pageInfo?.description &&  <meta property="og:description" content={pageInfo?.description} />}
        {pageInfo?.description && <meta property="twitter:description" content={pageInfo?.description} />}
        
        {pageInfo?.ogType && <meta property="og:type" content={pageInfo?.ogType} />}
        
        {pageInfo?.twitterCard && <meta property="twitter:card" content={pageInfo?.twitterCard} />}
        
        {pageInfo?.imagePreview?.publicUrl && <meta property="og:image" content={`${IMG_URL}${pageInfo.imagePreview.publicUrl}`} />}
        <meta name="keywords" content={pageInfo?.keywords} />
        <meta name="viewport" content="initial-scale=1.0, width=device-width"/>
      </Head>
      <Navbar allTextPage={allTextPages} allBlogs={allBlogs} />
      <Container>
        <h1 className="text-align-center">
          {pageInfo?.title}
        </h1>
        <br/>
        
        <div className="text-editor-container">
          { parse(pageContent || '') }
        </div>
        
        {/*<ContentPresenter nodes={nodes} slug={asPath} />*/}
      </Container>
      <Footer allTextPage={allTextPages} allBlogs={allBlogs} />
    </div>
  ) : null
}

export default TextPage
